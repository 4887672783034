<template>
  <v-sheet class="d-flex flex-column transparent" light>
    <div class="main" style="position: relative">
      <aspect-ratio-layout>
        <div class="d-flex flex-column justify-center align-center mx-4">
          <v-container v-if="hasAccess" fluid>
            <v-row class="pa-8">
              <v-col>
                <div class="d-flex align-center">
                  <AppBackButton> LEAVE ROOM </AppBackButton>
                  <div
                    class="route-name-background-container-meet-your-colleagues"
                  >
                    <span class="theatre-route-name">{{ room.name }}</span>
                  </div>
                </div>
              </v-col>
            </v-row>
            <v-row class="px-8">
              <v-col cols="8" style="background: #29252c" class="pa-12">
                <v-responsive :aspect-ratio="16 / 9">
                  <iframe
                    :src="src"
                    frameborder="0"
                    style="height: 100%; width: 100%; border-radius: 45px"
                  ></iframe
                ></v-responsive>
              </v-col>

              <v-col cols="4" class="py-0">
                <v-card
                  flat
                  class="rounded-0"
                  style="border: none"
                  v-if="room.questions"
                >
                  <v-card-title class="background white--text pa-6">
                    <h4>DARE TO... START A CONVERSATION</h4></v-card-title
                  >

                  <v-card-text>
                    <v-list class="pl-0">
                      <template v-for="(question, idx) in room.questions">
                        <div :key="idx">
                          <v-divider v-if="idx !== 0" class="mx-4"></v-divider>
                          <v-list-item class="pt-8 px-4 pb-4">
                            <div class="d-flex flex-column align-start">
                              <h4 class="black--text">
                                {{ question.name }}
                              </h4>
                              <p class="grey--text text-left pt-4">
                                {{ question.description }}
                              </p>
                            </div>
                          </v-list-item>
                        </div>
                      </template>
                    </v-list>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-container>

          <v-container v-else>
            <v-row style="height: 1080px" align="center" justify="center">
              <v-col class="d-flex flex-column justify-center align-center">
                <v-card dark class="pa-8">
                  <v-card-title class="justify-center mb-4">
                    <h2>The Breakout Room is full</h2>
                  </v-card-title>

                  <v-card-text class="justify-center mb-8">
                    <h4>
                      You can try to start or join another room on the Meet Your
                      Colleagues page.
                    </h4>
                  </v-card-text>
                  <v-card-actions class="d-flex justify-center">
                    <v-btn
                      color="primary"
                      class="font-weight-bold black--text"
                      :to="{ name: 'MeetYourColleagues' }"
                      >Go back</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </aspect-ratio-layout>
    </div>
  </v-sheet>
</template>

<script>
import axios from "axios";
import AspectRatioLayout from "../layouts/16by9Layout.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    AspectRatioLayout,
  },
  data() {
    return {
      room: {},
      intervalId: null,
    };
  },

  computed: {
    ...mapGetters(["me"]),
    hasAccess() {
      if (!this.room) return false;

      return this.room.current_participants <= this.room.max_participants;
    },
    src() {
      if (!this.room?.iframe_url) {
        return "";
      }

      return (
        this.room.iframe_url +
        `?embed&background=off&displayName=${this.me?.name}&chat&people&floatSelf&record=off`
      );
    },
  },

  async mounted() {
    await this.getBreakoutRoom();

    // Ping breakoutroom endpoint
    this.intervalId = setInterval(async () => {
      await this.ping();
    }, 15000);
  },

  async beforeDestroy() {
    // Clear ping invertal
    clearInterval(this.intervalId);

    // Leave breakoutroom
    await this.leave();

    console.log("Left breakoutroom");
  },

  methods: {
    async getBreakoutRoom() {
      const roomId = this.$route.params.id;
      const url = `${process.env.VUE_APP_API_URL}breakout/${roomId}`;

      const room = await axios.get(url);

      this.room = room.data;
    },
    async ping() {
      const url = `${process.env.VUE_APP_API_URL}breakout/${this.room.id}/ping`;

      await axios.get(url);
    },
    async leave() {
      const url = `${process.env.VUE_APP_API_URL}breakout/${this.room.id}/leave`;

      await axios.get(url);
    },
  },
};
</script>

<style scoped>
.main {
  background: linear-gradient(
      0deg,
      rgba(000, 000, 000, 0.7),
      rgba(000, 000, 000, 0.7)
    ),
    url("https://vod.eventcdn.net/pandora/smc2021/MeetYourColleagues_Bg.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

@media screen and (min-width: 1020px) {
  .main {
    background: linear-gradient(
        0deg,
        rgba(000, 000, 000, 0.7),
        rgba(000, 000, 000, 0.7)
      ),
      url("https://vod.eventcdn.net/pandora/smc2021/MeetYourColleagues_Bg.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    backface-visibility: hidden;
  }
}
</style>
